// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-agb-js": () => import("./../../../src/pages/agb.js" /* webpackChunkName: "component---src-pages-agb-js" */),
  "component---src-pages-coaching-js": () => import("./../../../src/pages/coaching.js" /* webpackChunkName: "component---src-pages-coaching-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-garten-js": () => import("./../../../src/pages/garten.js" /* webpackChunkName: "component---src-pages-garten-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-literatur-js": () => import("./../../../src/pages/literatur.js" /* webpackChunkName: "component---src-pages-literatur-js" */),
  "component---src-pages-mal-und-gestaltungstherapie-js": () => import("./../../../src/pages/mal-und-gestaltungstherapie.js" /* webpackChunkName: "component---src-pages-mal-und-gestaltungstherapie-js" */),
  "component---src-pages-natur-und-ihre-bedeutung-js": () => import("./../../../src/pages/natur-und-ihre-bedeutung.js" /* webpackChunkName: "component---src-pages-natur-und-ihre-bedeutung-js" */),
  "component---src-pages-portrait-js": () => import("./../../../src/pages/portrait.js" /* webpackChunkName: "component---src-pages-portrait-js" */),
  "component---src-pages-preise-js": () => import("./../../../src/pages/preise.js" /* webpackChunkName: "component---src-pages-preise-js" */),
  "component---src-pages-weiterbildung-js": () => import("./../../../src/pages/weiterbildung.js" /* webpackChunkName: "component---src-pages-weiterbildung-js" */)
}

